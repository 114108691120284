'use client';
import React, { useState } from 'react';
import Logo from '@/components/Logo';
import styles from './styles.module.scss';

const HamburgerMenu = ({
  isMobileView,
  isKOLPage,
  handleMenuClick,
  menuOpen
}: any) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const handleCloseMenu = () => {
    handleMenuClick();
  };

  return (
    <>
      {menuOpen && (
        <div className={styles.HamburgerWrapper}>
          <div className={styles.HamburgerContainer}>
            <div className={styles.navContainer}>
              <div className={styles.nav}>
                <Logo isMobileView={isMobileView} />
                <div className={styles.cta}>
                  <img
                    className='burger-icon'
                    src='/images_p/cross-icon.svg'
                    alt=''
                    loading='eager'
                    onClick={handleCloseMenu}
                  />
                </div>
              </div>
            </div>
            <div className={styles.menuContainer}>
              <div className={styles.menuBox}>
                <span className={styles.priceBox}>
                  <img
                    className='responsive'
                    src='/images_p/navbar_small_logo_crop.webp'
                    alt=''
                    loading='eager'
                  />
                  <span className={isKOLPage ? 'ptv kol' : 'ptv'}>PTV</span>
                </span>
                <ul>
                  <li>
                    <a
                      href={isKOLPage ? baseUrl + '/#overview' : '#overview'}
                      onClick={handleCloseMenu}
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      href={isKOLPage ? baseUrl + '/#HowItWork' : '#HowItWork'}
                      onClick={handleCloseMenu}
                    >
                      How It Works
                    </a>
                  </li>
                </ul>

                <div className={styles.cta}>
                  <a
                    className={styles.stackButton}
                    href='https://app.primetrader.com/auth/signup'
                  >
                    Stake
                  </a>
                  <a
                    className={styles.launchButton}
                    href='https://app.primetrader.com/auth/signup'
                  >
                    Launch DApp
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Navbar = ({ isMobileView, isKOLPage }: any) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClick = () => {
    console.log('-- menu clicked');
    setMenuOpen(!menuOpen);
  };

  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  const handleRedirect = () => {
    window.location.href = 'https://app.primetrader.com/auth/signup'; // Replace 'https://example.com' with your desired external URL
  };

  console.log(isMobileView, '--mobile');
  console.log(isKOLPage, '--isKOLPage');

  return (
    <>
      {isMobileView ? (
        <header className={styles.mobHeaderBox}>
          <div className={styles.mobContainer}>
            <div className={styles.nav}>
              <Logo isMobileView={isMobileView} />
              <div className={styles.cta}>
                <a
                  href='https://app.primetrader.com/auth/signup'
                  className='btn-pg'
                >
                  Launch DApp
                </a>
                <img
                  className='burger-icon'
                  src='/images_p/menu-icon.webp'
                  alt=''
                  loading='eager'
                  onClick={handleMenuClick}
                />
              </div>
            </div>
          </div>
          <HamburgerMenu
            isMobileView={isMobileView}
            isKOLPage={isKOLPage}
            handleMenuClick={handleMenuClick}
            menuOpen={menuOpen}
          />
        </header>
      ) : (
        <header
          className={`${styles.headerBox} ${isKOLPage && styles.kolPage}`}
        >
          <div className='logo-box'>
            <Logo />
          </div>
          <div className={styles.navBox}>
            <ul>
              <li>
                <a
                  style={{}}
                  href={isKOLPage ? baseUrl + '#overview' : '#overview'}
                >
                  Overview
                </a>
              </li>
              <li>
                <a
                  style={{}}
                  href={isKOLPage ? baseUrl + '#HowItWork' : '#HowItWork'}
                >
                  How It Works
                </a>
              </li>
            </ul>
            <span className={styles.priceBox}>
              <img
                className='responsive'
                src='/images_p/navbar_small_logo.webp'
                alt=''
                loading='eager'
              />
              <span className={isKOLPage ? 'ptv kol' : 'ptv'}>PTV</span>
            </span>
            <div
              className='RoadMapBtnOutline'
              style={{
                color: '#e6e6e6',
                fontFamily: 'var(--font-circularstdbook)',
                opacity: 1,
                marginRight: '25px'
              }}
              onClick={handleRedirect}
            >
              Stake
            </div>
            <div className='LaunchNavBtn' onClick={handleRedirect}>
              <label>Launch DApp</label>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Navbar;
