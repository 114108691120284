import React from 'react';
import FooterSocialLinksData, {
  FooterSocialTextData
} from '@/components/Footer/FooterSocialLinks';

const Footer = ({ isMobileView, isKOLPage }: any) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  return (
    <>
      {isMobileView ? (
        <>
          <section
            className={`mob-footer-section ${
              isKOLPage && 'transparent-background'
            }`}
          >
            <div
              className={`liner-shade ${isKOLPage && 'transparent-background'}`}
            >
              <div className='container'>
                <a href='index.html'>
                  <img src={'/images/newLogo.svg'} alt='prime-trader-logo' />
                </a>
                <p className='footer-passage'>
                  PrimeTrader offers fast financial onboarding to Web3 through
                  an integrated VASP-licensed infrastructure, the best on-chain
                  asset management, and GameFi,all powered by cutting-edge AI.
                </p>
                <div className='footer-links'>
                  <div className='footer-links-left'>
                    <ul>
                      <li>
                        <a href='#'>Home</a>
                      </li>
                      <li>
                        <a
                          href={
                            isKOLPage
                              ? baseUrl + '#InvestmentOpportunities'
                              : '#InvestmentOpportunities'
                          }
                        >
                          Investment Opportunities
                        </a>
                      </li>
                      <li>
                        <a href={isKOLPage ? baseUrl + '#GameFi' : '#GameFi'}>
                          GameFi
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='footer-links-right'>
                    <ul>
                      <li>
                        <a
                          href={
                            isKOLPage ? baseUrl + '#HowItWork' : '#HowItWork'
                          }
                        >
                          How PrimeTrader Works
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            isKOLPage
                              ? baseUrl + '#MarketUpdates'
                              : '#MarketUpdates'
                          }
                        >
                          Market Updates
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            isKOLPage
                              ? baseUrl + '#LadershipTeam'
                              : '#LadershipTeam'
                          }
                        >
                          Leadership Team
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='footer-social-box'>
                  <div className='footer-icons1'>
                    <a href={FooterSocialLinksData.facebook}>
                      <img
                        src='/images_p/footer/facbook.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='facebook'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.instagram}>
                      <img
                        src='/images_p/footer/instagram.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='instagram'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.linkedin}>
                      <img
                        src='/images_p/footer/linkedin.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='linkedin'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.email}>
                      <img
                        src='/images_p/footer/email.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='email'
                      />
                    </a>
                  </div>
                  <div className='footer-icons2'>
                    <a href={FooterSocialLinksData.twitter}>
                      <img
                        src='/images_p/footer/twitter.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='twitter'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.discord}>
                      <img
                        src='/images_p/footer/discord.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='discord'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.telegram}>
                      <img
                        src='/images_p/footer/telegram.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='telegram'
                      />
                    </a>{' '}
                    <a href={FooterSocialLinksData.medium}>
                      <img
                        src='/images_p/footer/medium.svg'
                        height={'30px'}
                        width={'30px'}
                        alt='medium'
                      />
                    </a>
                  </div>
                </div>
                <p className='copyright'>
                  © 2024 Prime Trader. All Rights Reserved. Terms of
                  Service.Privacy Policy.CCPA.
                </p>
              </div>
            </div>
          </section>
        </>
      ) : (
        <section
          className={`footer-section ${isKOLPage && 'transparent-background'}`}
        >
          <div
            className={`liner-shade ${isKOLPage && 'transparent-background'}`}
          >
            <div className='container'>
              <div className='new-flex-box'>
                <div className='footer-logo-box'>
                  <a href='index.html'>
                    <img src={'/images/newLogo.svg'} alt='prime-trader-logo' />
                  </a>
                  <p className='footer-passage'>
                    PrimeTrader offers fast financial onboarding to Web3 through
                    an integrated VASP-licensed infrastructure, the best
                    on-chain asset management, and GameFi, all powered by
                    cutting-edge AI.
                  </p>
                  <p className='copyright'>
                    © 2024 Prime Trader. All Rights Reserved. Terms of
                    Service.Privacy Policy.CCPA.
                  </p>
                </div>
                <div className='icons-container'>
                  <p className={'titles'}>Socials</p>
                  <div className='new-icons-box'>
                    <div className='footer-icons1'>
                      <a
                        className={'footer-link'}
                        href={FooterSocialLinksData.facebook}
                      >
                        <img
                          src='/images_p/footer/facbook.svg'
                          height={'30px'}
                          width={'30px'}
                          alt='facebook'
                        />
                        <p>{FooterSocialTextData.facebook}</p>
                      </a>{' '}
                      <a
                        className='footer-link'
                        href={FooterSocialLinksData.instagram}
                      >
                        <img
                          src='/images_p/footer/instagram.svg'
                          height={'30px'}
                          width={'30px'}
                          alt='facebook'
                        />
                        <p>{FooterSocialTextData.instagram}</p>
                      </a>{' '}
                      <a
                        className={'footer-link'}
                        href={FooterSocialLinksData.linkedin}
                      >
                        <img
                          src='/images_p/footer/linkedin.svg'
                          height={'30px'}
                          width={'30px'}
                          alt='facebook'
                        />
                        <p>{FooterSocialTextData.linkedin}</p>
                      </a>{' '}
                      <a
                        className={'footer-link'}
                        href={FooterSocialLinksData.email}
                      >
                        <img
                          src='/images_p/footer/email.svg'
                          height={'30px'}
                          width={'30px'}
                          alt='facebook'
                        />
                        <p>{FooterSocialTextData.email}</p>
                      </a>
                    </div>
                    <div className='footer-icons2'>
                      <a
                        className={'footer-link'}
                        href={FooterSocialLinksData.twitter}
                      >
                        <img
                          src='/images_p/footer/twitter.svg'
                          height={'30px'}
                          width={'30px'}
                          alt='facebook'
                        />
                        <p>{FooterSocialTextData.twitter}</p>
                      </a>{' '}
                      <a
                        className={'footer-link'}
                        href={FooterSocialLinksData.discord}
                      >
                        <img
                          src='/images_p/footer/discord.svg'
                          height={'30px'}
                          width={'30px'}
                          alt='facebook'
                        />
                        <p>{FooterSocialTextData.discord}</p>
                      </a>{' '}
                      <a
                        className={'footer-link'}
                        href={FooterSocialLinksData.telegram}
                      >
                        <img
                          src='/images_p/footer/telegram.svg'
                          height={'30px'}
                          width={'30px'}
                          alt='facebook'
                        />
                        <p>{FooterSocialTextData.telegram}</p>
                      </a>{' '}
                      <a
                        className={'footer-link'}
                        href={FooterSocialLinksData.medium}
                      >
                        <img
                          src='/images_p/footer/medium.svg'
                          height={'30px'}
                          width={'30px'}
                          alt='medium'
                        />
                        <p>{FooterSocialTextData.medium}</p>
                      </a>
                    </div>
                  </div>
                </div>

                {isKOLPage ? (
                  <div className='icons-container'>
                    <p className={'titles center'}>Navigation</p>
                    <div className={'navigation'}>
                      <a href={baseUrl}>Home</a>
                      <a href={baseUrl + '/#HowItWork'}>
                        How PrimeTrader Works
                      </a>
                      <a href={baseUrl + '#InvestmentOpportunities'}>
                        Investment Opportunities
                      </a>
                      <a href={baseUrl + '#MarketUpdates'}>Market Updates</a>
                      <a href={baseUrl + '#LadershipTeam'}>Leadership Team</a>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className='footer-links'>
                      <p className={'titles'}>Navigation</p>
                      <ul>
                        <li>
                          <a href='#'>Home</a>
                        </li>
                        <li>
                          <a href={'#HowItWork'}>How PrimeTrader Works</a>
                        </li>
                        <li>
                          <a href='#InvestmentOpportunities'>
                            Investment Opportunities
                          </a>
                        </li>
                        <li>
                          <a href='#MarketUpdates'>Market Updates</a>
                        </li>
                        <li>
                          <a href='#GameFi'>GameFi</a>
                        </li>
                        <li>
                          <a href='#LadershipTeam'>Leadership Team</a>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Footer;
