
// type defination
export interface FooterSocialLinks {
  twitter: string;
  instagram: string;
  linkedin: string;
  facebook: string;
  email: string;
  discord: string;
  telegram: string;
  medium: string;
}

// list of members
const FooterSocialLinksData: FooterSocialLinks = {
  facebook: "https://www.facebook.com/PrimeTrader.PT",
  email: 'mailto:contact@primetrader.com',
  twitter: "https://twitter.com/PrimeTraderX",
  instagram: "https://www.instagram.com/primetraderx/",
  linkedin: "https://www.linkedin.com/company/primetraderx",
  discord: 'https://discord.gg/WKfzBp6ETC',
  telegram: 'https://t.me/primetrader_x',
  medium: 'https://medium.com/@PrimeTraderX',
}

export const FooterSocialTextData: FooterSocialLinks = {
  facebook: "Facebook",
  email: 'Gmail',
  twitter: "X",
  instagram: "Instagram",
  linkedin: "Linkedin",
  discord: 'Discord',
  telegram: 'Telegram',
  medium: 'Medium',
}

export default FooterSocialLinksData;
